export const UpcomingList = [
  {
    name: "Resort",
    description:
      " Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet.",
    thumpnail: require("../images/completed/resort_1/thumpnail.jpeg"),
    images: [
      { url: require("../images/completed/resort_1/img4.jpeg") },
      { url: require("../images/completed/resort_1/img3.jpeg") },
    ],
  },
  {
    name: "Resort",
    description: " Lorem ipsum dolor sit amet.",
    thumpnail: require("../images/completed/resort_1/thumpnail.jpeg"),
    images: [
      { url: require("../images/completed/resort_1/img4.jpeg") },
      { url: require("../images/completed/resort_1/img3.jpeg") },
    ],
  },
  {
    name: "Resort 3",
    description: " Lorem ipsum dolor sit amet.",
    thumpnail: require("../images/completed/resort_1/thumpnail.jpeg"),
    images: [
      { url: require("../images/completed/resort_1/img4.jpeg") },
      { url: require("../images/completed/resort_1/img3.jpeg") },
    ],
  },
  {
    name: "Resort 4",
    description: " Lorem ipsum dolor sit amet.",
    thumpnail: require("../images/completed/resort_1/thumpnail.jpeg"),
    images: [
      { url: require("../images/completed/resort_1/img1.jpeg") },
      { url: require("../images/completed/resort_1/img1.jpeg") },
    ],
  },
];
export default UpcomingList;
