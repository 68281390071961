export const CompletedList = [
  {
    name: "Resort",
    description:
      " Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet.",
    thumpnail: require("../images/completed/resort_1/thumpnail.jpeg"),
    images: [
      { url: require("../images/completed/resort_1/img4.jpeg") },
      { url: require("../images/completed/resort_1/img3.jpeg") },
    ],
  },

  {
    name: "Resort",
    description: " Lorem ipsum dolor sit amet.",
    thumpnail: require("../images/completed/resort_1/thumpnail.jpeg"),
    images: [
      { url: require("../images/completed/resort_1/img4.jpeg") },
      { url: require("../images/completed/resort_1/img3.jpeg") },
    ],
  },
];
export default CompletedList;
