import React from "react";
import "./footer.css";
import logo from "../../images/fes_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className="footer-container ">
      <div className="footer-logo">
        <img className="fooler-img" src={logo} alt="Forever Structures" />
      </div>
      <div className="contact-info">
        <div className="connect-head">Connect with us</div>
        <div className="phone connect-item">
          <FontAwesomeIcon icon={faPhoneVolume} />
          <a href="tel:+919447645522" className="connect-text">
            +91 9447645522
          </a>
        </div>
        <div
          className="mail connect-item"
          onClick={() =>
            (window.location.href = "mailto:info@foreverstructures.in")
          }
        >
          <FontAwesomeIcon icon={faEnvelope} />
          <p className="connect-text">info@foreverstructures.in</p>
        </div>
        <div className="social-icon">
          <a className="icon-link" href="https://wa.me/919447645522?text=Hello">
            <FontAwesomeIcon className="icon-style" icon={faWhatsapp} />
          </a>
          <a
            href="https://www.facebook.com/jijo.kj.165?mibextid=zLoPMf"
            className="icon-link"
          >
            <FontAwesomeIcon className="icon-style" icon={faFacebook} />
          </a>
          <a
            href="https://instagram.com/forever_structures?igshid=NzZlODBkYWE4Ng=="
            className="icon-link"
          >
            <FontAwesomeIcon className="icon-style" icon={faInstagram} />
          </a>
        </div>
      </div>
      {/* <div className="address">address</div> */}
    </div>
  );
};

export default Footer;
