import React from "react";
import "./services.css";

const Services = () => {
  return (
    <div className="services-container">
      <div className="bg-mask"></div>
      <div className="container index">
        <div className="head head-customize">What We Do</div>
        <div className="serv-wrapper">
          <div className="serv-title">Real Estate Consulting</div>
          <div className="serv-description">
            Our real estate consultancy service is your trusted partner in
            finding the perfect property, offering expert guidance and
            personalized solutions. Whether you're buying, selling, or
            investing, we're here to make your real estate dreams a reality.
          </div>
        </div>
        <div className="serv-wrapper">
          <div className="serv-title">Property Development</div>
          <div className="serv-description">
            We bring dream like destinations to life, crafting exceptional and
            immersive experiences in some of the kerala’s most breathtaking
            locales.
          </div>
        </div>
        <div className="serv-wrapper">
          <div className="serv-title">Renovation and Remodeling </div>
          <div className="serv-description">
            Elevating the character and performance of existing structures
            through expert craftsmanship, enhancing both functionality and
            aesthetics to create spaces that truly shine.
          </div>
        </div>
        <div className="serv-wrapper">
          <div className="serv-title">Construction</div>
          <div className="serv-description">
            Turning Your Vision into Construction Perfection.
          </div>
        </div>
        <div className="serv-wrapper">
          <div className="serv-title">Landscape and Pool Construction</div>
          <div className="serv-description">
            Showcasing our talent in crafting resort landscapes that captivate
            the senses and designing functional, inviting swimming pools and
            recreational spaces that elevate the guest experience.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
