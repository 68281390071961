import React from "react";
import "./landing.css";
import { MDBBtn } from "mdb-react-ui-kit";
import LandingImg from "../../images/landing-img.jpeg";

const Landing = () => {
  return (
    <div className="landing-container container">
      <div className="home-text">
        <div className="first-line">
          <p className="big-text">Built For </p>
          <p className="big-text blue">Ever</p>
        </div>
        <div className="second-line">
          <p className="big-text blue"> Trust </p>
          <p className="big-text">For Ever</p>
        </div>
        <p className="samll-text">
          Creating enduring relationships and results that stand the test of
          time
        </p>
        <div className="btn-wrapper">
          <MDBBtn className="me-1 fes-btn">Contact Us</MDBBtn>
        </div>
      </div>
      <div className="home-img">
        <img
          src={LandingImg}
          alt="Resort construction in Vagamon"
          className="landing-img"
        />
      </div>
    </div>
  );
};

export default Landing;
