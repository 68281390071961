import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/home/home";
import CompletedGalary from "./components/completed-galary/completed-galary";
import UpcomingGalary from "./components/upcoming-galary/upcoming-galary";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="/galary/completed-projects/:project_id"
            element={<CompletedGalary />}
          />
          <Route
            path="/galary/upcoming-projects/:project_id"
            element={<UpcomingGalary />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
