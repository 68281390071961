import React from "react";
import "./brand.css";
import logo from "../../images/fes_logo.png";

const Brand = () => {
  return (
    <div className="brand-component">
      <img className="logo" src={logo} alt="Forever Structures" />
      {/* <div className="brand-name">Forever Structures</div>{" "} */}
    </div>
  );
};

export default Brand;
