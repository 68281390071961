import React, { useState } from "react";
import "./completed.css";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBBtn,
  MDBModalBody,
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselItem,
} from "mdb-react-ui-kit";
// import CompletedList from "../../json/completed_projects.json";
import CompletedList from "../../data/completed-list";
import { Link } from "react-router-dom";

const Completed = () => {
  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => setBasicModal(!basicModal);
  //

  return (
    <div className="completed ">
      <div className="container">
        <div className="head head-cp">Completed Projects</div>
        <div className="tile-wrapper">
          {CompletedList.map((list, index) => (
            <div className="bg-image overlay  tile">
              <img
                // src="https://mdbootstrap.com/img/new/fluid/city/055.webp"
                src={list.thumpnail}
                className="img-fluid img-fill"
                alt="construction in vagamon"
              />
              <Link to={"/galary/completed-projects/" + index}>
                <div className="mask overlay  tile-mask" onClick={toggleOpen}>
                  <p className="tile-title">{list.name}</p>
                  <p className="tile-desc">{list.description} </p>
                  <p className="title-action">Click to see more</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Completed;
