import React from "react";
import "./upcoming.css";
import { Link } from "react-router-dom";
import UpcomingList from "../../data/upcoming-list";

const Upcoming = () => {
  return (
    <div className="upcoming-container container">
      <div className="head head-up">Upcoming Projects</div>
      <div className="tile-wrapper">
        {UpcomingList.map((list, index) => (
          <div className="bg-image overlay  tile">
            <img
              // src="https://mdbootstrap.com/img/new/fluid/city/055.webp"
              src={list.thumpnail}
              className="img-fluid img-fill"
              alt="construction in vagamon"
            />
            <Link to={"/galary/upcoming-projects/" + index}>
              <div className="mask overlay  tile-mask">
                <p className="tile-title">{list.name}</p>
                <p className="tile-desc">{list.description} </p>
                <p className="title-action">Click to see more</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Upcoming;
