import React, { useState } from "react";
import "./upcoming-galary.css";
import { Link, useParams } from "react-router-dom";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBBtn,
  MDBModalBody,
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
} from "mdb-react-ui-kit";
import UpcomingList from "../../data/upcoming-list";

const UpcomingGalary = () => {
  const [basicModal, setBasicModal] = useState(!false);
  const params = useParams();
  const projectID: number = Number(params.project_id);
  //
  return (
    <div className="galary-container">
      <MDBModal open={basicModal} setopen={setBasicModal} tabIndex="-1">
        <MDBModalDialog className="modal-customization">
          <MDBModalContent className=" transpatent-bg content-cstm">
            <MDBModalHeader className="header-cstm">
              <Link to={"/"}>
                <MDBBtn
                  className="btn-close btn-close-white"
                  color="none"
                  // onClick={toggleOpen}
                ></MDBBtn>
              </Link>
            </MDBModalHeader>
            <MDBModalBody className="modal-body-cstm">
              {/* Carousel */}
              <MDBCarousel showControls showIndicators interval={99999999999}>
                {UpcomingList[projectID].images.map((image: any, index) => (
                  <MDBCarouselItem itemId={index + 1}>
                    <div className="item-wrapper">
                      <img
                        // src="https://mdbootstrap.com/img/new/slides/041.jpg"
                        src={image.url}
                        className="d-block  carousel-img"
                        alt="..."
                      />
                      <MDBCarouselCaption>
                        {projectID}
                        <h5>{UpcomingList[projectID].name}</h5>
                        <p>{UpcomingList[projectID].description}</p>
                      </MDBCarouselCaption>
                    </div>
                  </MDBCarouselItem>
                ))}
              </MDBCarousel>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default UpcomingGalary;
