import React, { useRef, useState } from "react";
import "./home.css";
import Navbar from "../navbar/navbar";
import Landing from "../landing/landing";
import Services from "../services/services";
import Completed from "../completed/completed";
import Upcoming from "../upcoming/upcoming";
import Footer from "../footer/footer";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Brand from "../brand/brand";

const Home = () => {
  const [openBasic, setOpenBasic] = useState(false);
  //
  const scollToService = useRef<null | HTMLDivElement>(null);
  const scollToHome = useRef<null | HTMLDivElement>(null);
  const scollToCompleted = useRef<null | HTMLDivElement>(null);
  const scollToUpcoming = useRef<null | HTMLDivElement>(null);

  //
  return (
    <div className="home-component ">
      <div className="blue-top"></div>
      <div className="navbar-container">
        <MDBNavbar fixed="top" expand="md" className="navbarMain">
          <MDBContainer fluid>
            <MDBNavbarBrand href="#">
              <Brand />
            </MDBNavbarBrand>

            <MDBNavbarToggler
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setOpenBasic(!openBasic)}
            >
              <MDBIcon icon="bars" fas />
            </MDBNavbarToggler>

            <MDBCollapse navbar open={openBasic}>
              <MDBNavbarNav right fullWidth={false} className="  mb-2 mb-lg-0">
                <MDBNavbarItem>
                  <Link to="#">
                    <MDBNavbarLink
                      aria-current="page"
                      onClick={() =>
                        scollToHome.current?.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      Home
                    </MDBNavbarLink>
                  </Link>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink
                    onClick={() =>
                      scollToService.current?.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                  >
                    What we do
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink
                    onClick={() =>
                      scollToCompleted.current?.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                  >
                    Completed Projects
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink
                    onClick={() =>
                      scollToUpcoming.current?.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                  >
                    Upcoming Projects
                  </MDBNavbarLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </div>

      {/* <Navbar /> */}
      <div ref={scollToHome}></div>

      <Landing />
      <div ref={scollToService}></div>
      <Services />
      <div ref={scollToCompleted}></div>

      <Completed />
      <div ref={scollToUpcoming}></div>

      <Upcoming />
      <Footer />
    </div>
  );
};

export default Home;
